<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader
        :pageName="pageName"
        :pageNavs="$permission.isGroup() ? groupNavs : pageNavs"
      />
      <section class="section">
        <div class="row">
          <div class="col-8">
            <Panel type="board">
              <template v-slot:headline>検索条件</template>
              <template v-slot:body>
                <div class="search">
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>クーポン名</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  v-trim
                                  name="name"
                                  v-maxlength
                                  maxlength='50'
                                  v-model="searchForm.name"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>発行元</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select
                                  class="form-control form-select-input"
                                  name="issuer"
                                  v-model="searchForm.issuer"
                                >
                                  <option value="">すべて</option>
                                  <option
                                    v-for="item in issuerTypes"
                                    :key="item.value"
                                    :value="item.value"
                                  >{{ item.label }}</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>利用日</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-group">
                                <div class="form-group-item">
                                  <div class="form-icon form-date">
                                    <Flatpickr
                                      class="form-control form-icon-input form-date-input"
                                      name="startDate"
                                      v-model="searchForm.startDate"
                                      :config="config.noLimit"
                                    />
                                  </div>
                                </div>
                                <div class="form-group-item">
                                  〜
                                </div>
                                <div class="form-group-item">
                                  <div class="form-icon form-date">
                                    <Flatpickr
                                      class="form-control form-icon-input form-date-input"
                                      name="endDate"
                                      v-model="searchForm.endDate"
                                      :config="config.noLimit"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:contentFooter>
                <ul class="listGrid justify-content-end">
                  <li class="listGrid-item">
                    <button
                      class="btn btn-white"
                      type="button"
                      @click="resetSearch"
                    >
                      リセット
                    </button>
                  </li>
                  <li class="listGrid-item">
                    <button class="btn btn-main" type="button" @click="search">
                      検索
                    </button>
                  </li>
                </ul>
              </template>
            </Panel>
          </div>
          <div class="col-4">
            <Panel type="board" :padding="false" :center="true">
              <template v-slot:body>
                <NumDisplay position="center" color="dark">
                  <template v-slot:title>当月利用件数</template>
                  <template v-slot:num>{{ couponUseTotal | comma }}</template>
                  <template v-slot:sup>件</template>
                </NumDisplay>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:body>
                <div class="scrollX">
                  <TableWithCheckbox
                    :dataTable="
                      $permission.isGroup() ? dataGroupTable : dataTable
                    "
                    :labels="labels"
                  />
                </div>
              </template>
              <template v-slot:footer>
                <PaginationLog
                  :modulePath="modulePath"
                  :searchConditions="searchConditions"
                  :subdomain="subdomain"
                  :shopId="shopId"
                  :listLength="couponUseCount"
                ></PaginationLog>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item">
            <ActionButton :disabled="!couponUseList.length || isDisable" class="btn btn-black" :handle-submit="hanldeClickDownload" button-text="CSVダウンロード" />
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
    <ModalConfirmDownload
      v-if="downloadCSVModal"
      @cancelDownloadCsv="cancelDownloadCsv"
      @acceptDownloadCsv="acceptDownloadCsv"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Flatpickr from 'vue-flatpickr-component';
//mixins
import nav from '@/mixins/nav/coupon';
import flatpickr from '@/mixins/plugin/flatpickr';
import search from '@/mixins/plugin/search';
import download from '@/mixins/plugin/download';
import { formatDateAndTime, addThreeDotEndLine } from '@/helpers/formatData';
//component
import FormRow from '@/components/FormRow.vue';
import NumDisplay from '@/components/NumDisplay.vue';
import PaginationLog from '@/components/PaginationLog.vue';
import TableWithCheckbox from '@/components/TableWithCheckbox.vue';
import ModalConfirmDownload from '@/components/ModalConfirmDownload.vue';
//constants
import { CouponConstants } from '@/constants/coupon';

export default {
  name: 'ShopCouponUse',
  data: function() {
    return {
      pageName: 'クーポン',
      groupNavs: [
        {
          id: '01',
          name: 'クーポン一覧',
          path: '/shop/coupon',
          routeName: 'ShopCoupon',
          includes: false,
        },
        {
          id: '02',
          name: '利用実績',
          path: '/shop/coupon/use',
          routeName: 'ShopCouponUse',
          includes: false,
        },
      ],
      modulePath: this.$permission.isGroup()
        ? 'coupon/getEventUseCoupons'
        : 'coupon/getShopUseCoupons',
      searchConditions: {},
      storeModule: 'coupon',
      searchFields: ['name', 'issuer', 'startDate', 'endDate'],
      isDisable: false,
      issuerTypes: Object.values(CouponConstants.issuerType),
      storedField: 'idRequestExportShopCoupon',
    };
  },
  mixins: [nav, flatpickr, search, download],
  components: {
    FormRow,
    NumDisplay,
    Flatpickr,
    PaginationLog,
    TableWithCheckbox,
    ModalConfirmDownload,
  },
  computed: {
    ...mapGetters({
      couponUseList: 'coupon/couponUseList',
      userInfo: 'auth/infor',
      couponUseCount: 'coupon/couponUseCount',
      couponUseTotal: 'coupon/couponUseTotal',
      parentEventDetail: 'event/parentEventDetail',
      isGmoOrOfficeLoginStore: 'common/isGmoOrOfficeLoginStore',
      isGmoLoginStore: 'common/isGmoLoginStore',
      hasCustomersRole: 'auth/hasCustomersRole',
      subdomain: 'auth/commonSubdomain',
      shopId: 'auth/commonShopId',
      isGmoOrOfficeLoginStoreAndStore: 'common/isGmoOrOfficeLoginStoreAndStore',
    }),
    dataTable() {
      return (
        this.couponUseList &&
        this.couponUseList.map((item) => {
          return {
            customerName: (this.parentEventDetail.showCustomerFlag || this.isGmoOrOfficeLoginStore) ? item.customer?.nameSei + ' ' + item.customer?.nameMei : '',
            userId: item.customer?.number,
            createDate: formatDateAndTime(item.createDate),
            name: addThreeDotEndLine(item.name, 15),
            issuer: item?.issuer
          };
        })
      );
    },
    dataGroupTable() {
      return (
        this.couponUseList &&
        this.couponUseList.map((item) => {
          return {
            customerName: this.parentEventDetail.showCustomerFlag ? item.customer?.nameSei + ' ' + item.customer?.nameMei : '',
            userId: item.customer?.number,
            createDate: formatDateAndTime(item.createDate),
            name: addThreeDotEndLine(item?.name, 15),
            shopName: addThreeDotEndLine(item.shop?.name, 15),
            issuer: item?.issuer
          };
        })
      );
    },
    isShowCustomerCol() {
      if (this.isGmoOrOfficeLoginStore) {
        return this.$permission.isGmo() || (this.$permission.isOffice() && this.hasCustomersRole);
      }
      return this.hasCustomerCol;
    },
    labels() {
      return [
        ...this.isShowCustomerCol ? [{ key: 'customerName', name: 'お名前' }] : [],
        { key: 'userId', name: 'user ID' },
        { key: 'createDate', name: '利用日' },
        { key: 'name', name: 'クーポン名', tdClass: 'dataTable-break w-145' },
        ...this.$permission.isGroup() ? [{ key: 'shopName', name: '利用店舗', tdClass: 'dataTable-break w-145' }] : [],
        { key: 'issuer', name: '発行元' },
      ];
    },
    hasCustomerCol() {
      return this.parentEventDetail.showCustomerFlag &&
        this.couponUseList?.some(element => element?.customer?.nameSei || element?.customer?.nameMei);
    },
  },
  methods: {
    async hanldeClickDownload() {
      this.isDisable = true;
      let result;
      if (this.isGmoOrOfficeLoginStoreAndStore) {
        result = await this.$store.dispatch('coupon/getShopCouponCSV', {
          ...this.payloadDownloadCsv,
          subdomain: this.subdomain,
          shopId: this.shopId,
        });
      } else {
        result = await this.$store.dispatch('coupon/getEventCouponCSV', {
          ...this.payloadDownloadCsv,
          subdomain: this.subdomain,
        });
      }
      if (result) {
        this.saveIdRequestExport(result.id);
        this.checkExportCsvStatus(result.id);
      }
    },
  },
  async created() {
    const loading = this.$loading.show();
    if (this.isGmoOrOfficeLoginStoreAndStore){
      await this.$store.dispatch('coupon/getShopCouponUseTotal', { subdomain: this.subdomain, shopId: this.shopId });
    } else {
      await this.$store.dispatch('coupon/getEventCouponUseTotal', { subdomain: this.subdomain });
    }
    this.$loading.clear(loading);
  }
};
</script>
